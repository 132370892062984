import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-1ee4e10c0c7e1bd5ff9cd70d1f3c03521abf21ea7ee45aa92deab16f87af4dbb.js"
          async
        />
        <p id="skip-link">
          <a href="#main-menu" className="element-invisible element-focusable">
            Jump to navigation
          </a>
        </p>
        <div id="mobile-sidebar">
          <div className="mobile-logo-wrapper">
            <a href="/" title="Inicio" rel="home" className="mobile-logo">
              <img
                src="/sites/all/themes/custom/iniston_zen/logo.png"
                alt="Inicio"
              />
            </a>
          </div>
          <div className="region region-mobile-sidebar">
            <div
              id="block-search-form"
              className="block block-search first odd"
              role="search"
            >
              
            </div>
            <div
              id="block-system-main-menu"
              className="block block-system block-menu last even"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-expanded first expanded">
                  <a href="/la-tos/" className="menu__link">
                    La tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a href="/la-tos/que-es-la-tos/" className="menu__link">
                        ¿Qué es la tos?
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="menu__link"
                          >
                            Definición
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/que-es-la-tos/vivencia/"
                            className="menu__link"
                          >
                            Vivencia
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/que-es-la-tos/funcionamiento/"
                            className="menu__link"
                          >
                            Funcionamiento
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/tipos-de-tos/seca/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/tipos-de-tos/con-mocos/"
                            className="menu__link"
                          >
                            Tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                        className="menu__link"
                      >
                        Momentos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="menu__link"
                          >
                            Gripe
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/nocturna/"
                            className="menu__link"
                          >
                            Noche
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/alergia/"
                            className="menu__link"
                          >
                            Alergia
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/verano/"
                            className="menu__link"
                          >
                            Verano
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/fumador/"
                            className="menu__link"
                          >
                            Fumador
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a
                        href="/la-tos/como-aliviar-la-tos/"
                        className="menu__link"
                      >
                        Cómo aliviar la tos
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded expanded">
                  <a href="/remedios/" className="menu__link">
                    Remedios contra la tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-leaf first leaf">
                      <a
                        href="/remedios/caseros-y-naturales/"
                        className="menu__link"
                      >
                        Remedios caseros
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/remedios/tos-nocturna/" className="menu__link">
                        Tos nocturna
                      </a>
                    </li>
                    <li className="menu__item is-expanded last expanded">
                      <a
                        href="/remedios/contra-la-tos/seca/"
                        className="menu__link"
                      >
                        Recetas contra la tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="menu__link"
                          >
                            Remedios tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/remedios/contra-la-tos/mocos/"
                            className="menu__link"
                          >
                            Remedios tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-active-trail is-expanded expanded active-trail">
                  <a
                    href="/jarabe-iniston/"
                    className="menu__link is-active-trail active-trail"
                  >
                    Productos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/jarabe-iniston/expectorante-descongestivo/"
                        className="menu__link"
                      >
                        Adultos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="menu__link"
                          >
                            Iniston expectorante y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/expectorante/"
                            className="menu__link"
                          >
                            Iniston expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo-descongestivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-iniston/mucolitico/"
                            className="menu__link"
                          >
                            Iniston mucolítico
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-active-trail is-expanded last expanded active-trail">
                      <a
                        href="/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link is-active-trail active-trail"
                      >
                        Niños
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Instolin pediátrico expectorante
                          </a>
                        </li>
                        <li className="menu__item is-active-trail is-leaf last leaf active-trail">
                          <a
                            href="/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link is-active-trail active-trail active"
                          >
                            Inistolin pediátrico antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded last expanded">
                  <a href="/tos-ninos/" className="menu__link">
                    Tos en niños
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                            className="menu__link"
                          >
                            Tos con mocos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Productos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Inistolin expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/remedios/" className="menu__link">
                        Remedios para la tos en niños
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/causas/" className="menu__link">
                        Causas
                      </a>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/desarrollo-infantil/7-anos/"
                        className="menu__link"
                      >
                        Desarrollo infantil
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="menu__link"
                          >
                            7 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/8-anos/"
                            className="menu__link"
                          >
                            8 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/9-anos/"
                            className="menu__link"
                          >
                            9 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/10-anos/"
                            className="menu__link"
                          >
                            10 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/11-anos/"
                            className="menu__link"
                          >
                            11 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/12-anos/"
                            className="menu__link"
                          >
                            12 años
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a href="/dibujos-para-colorear/" className="menu__link">
                        Diviértete con Pandolín
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="page">
          <div className="header-wrapper">
            <header className="header" id="header" role="banner">
              <div className="header-top">
                <div className="header-top-left">
                  <span id="mobile-button" />
                </div>
                <a
                  href="/"
                  title="Inicio"
                  rel="home"
                  className="header__logo"
                  id="logo"
                >
                  <img
                    src="/sites/all/themes/custom/iniston_zen/logo.png"
                    alt="Inicio"
                    className="header__logo-image"
                  />
                </a>
                <div className="region region-pre-header">
                  <div
                    id="block-search-form--2"
                    className="block block-search first odd"
                    role="search"
                  >
                    
                  </div>
                  <div
                    id="block-block-3"
                    className="block block-block last even"
                  >
                    <p>
                    <img 
                      alt="" 
                      className="attr__format__media_original img__fid__142 img__view_mode__media_original media-image header-map-pin" 
                      src="/sites/default/files/marker.png"
                    />
                      <strong>Dónde comprar Iniston</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="header__region region region-header">
                <div
                  id="block-superfish-1"
                  className="block block-superfish first last odd"
                >
                  <ul
                    id="superfish-1"
                    className="menu sf-menu sf-main-menu sf-horizontal sf-style-none sf-total-items-4 sf-parent-items-4 sf-single-items-0"
                  >
                    <li
                      id="menu-376-1"
                      className="first sf-item-1 sf-depth-1 sf-total-children-4 sf-parent-children-3 sf-single-children-1 menuparent"
                    >
                      <a href="/la-tos/" className="sf-depth-1 menuparent">
                        La tos
                      </a>
                      <ul>
                        <li
                          id="menu-382-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-3 sf-parent-children-0 sf-single-children-3 menuparent"
                        >
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="sf-depth-2 menuparent"
                          >
                            ¿Qué es la tos?
                          </a>
                          <ul>
                            <li
                              id="menu-555-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/"
                                className="sf-depth-3"
                              >
                                Definición
                              </a>
                            </li>
                            <li
                              id="menu-540-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/vivencia/"
                                className="sf-depth-3"
                              >
                                Vivencia
                              </a>
                            </li>
                            <li
                              id="menu-541-1"
                              className="last sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/funcionamiento/"
                                className="sf-depth-3"
                              >
                                Funcionamiento
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-460-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-461-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/seca/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-462-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/con-mocos/"
                                className="sf-depth-3"
                              >
                                Tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-467-1"
                          className="middle sf-item-3 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="sf-depth-2 menuparent"
                          >
                            Momentos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-470-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                                className="sf-depth-3"
                              >
                                Gripe
                              </a>
                            </li>
                            <li
                              id="menu-472-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/nocturna/"
                                className="sf-depth-3"
                              >
                                Noche
                              </a>
                            </li>
                            <li
                              id="menu-474-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/alergia/"
                                className="sf-depth-3"
                              >
                                Alergia
                              </a>
                            </li>
                            <li
                              id="menu-476-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/verano/"
                                className="sf-depth-3"
                              >
                                Verano
                              </a>
                            </li>
                            <li
                              id="menu-478-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/fumador/"
                                className="sf-depth-3"
                              >
                                Fumador
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-466-1"
                          className="last sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/la-tos/como-aliviar-la-tos/"
                            className="sf-depth-2"
                          >
                            Cómo aliviar la tos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-377-1"
                      className="middle sf-item-2 sf-depth-1 sf-total-children-3 sf-parent-children-1 sf-single-children-2 menuparent"
                    >
                      <a href="/remedios/" className="sf-depth-1 menuparent">
                        Remedios contra la tos
                      </a>
                      <ul>
                        <li
                          id="menu-479-1"
                          className="first sf-item-1 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/caseros-y-naturales/"
                            className="sf-depth-2"
                          >
                            Remedios caseros
                          </a>
                        </li>
                        <li
                          id="menu-480-1"
                          className="middle sf-item-2 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/tos-nocturna/"
                            className="sf-depth-2"
                          >
                            Tos nocturna
                          </a>
                        </li>
                        <li
                          id="menu-454-1"
                          className="last sf-item-3 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Recetas contra la tos
                          </a>
                          <ul>
                            <li
                              id="menu-458-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/seca/"
                                className="sf-depth-3"
                              >
                                Remedios tos seca
                              </a>
                            </li>
                            <li
                              id="menu-459-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/mocos/"
                                className="sf-depth-3"
                              >
                                Remedios tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-378-1"
                      className="active-trail middle sf-item-3 sf-depth-1 sf-total-children-2 sf-parent-children-2 sf-single-children-0 menuparent"
                    >
                      <a
                        href="/jarabe-iniston/"
                        className="sf-depth-1 menuparent"
                      >
                        Productos
                      </a>
                      <ul>
                        <li
                          id="menu-449-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="sf-depth-2 menuparent"
                          >
                            Adultos
                          </a>
                          <ul>
                            <li
                              id="menu-447-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-446-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante
                              </a>
                            </li>
                            <li
                              id="menu-445-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-444-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo
                              </a>
                            </li>
                            <li
                              id="menu-443-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/mucolitico/"
                                className="sf-depth-3"
                              >
                                Iniston mucolítico
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-451-1"
                          className="active-trail last sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Niños
                          </a>
                          <ul>
                            <li
                              id="menu-442-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Instolin pediátrico expectorante
                              </a>
                            </li>
                            <li
                              id="menu-441-1"
                              className="active-trail last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3 active"
                              >
                                Inistolin pediátrico antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-379-1"
                      className="last sf-item-4 sf-depth-1 sf-total-children-6 sf-parent-children-3 sf-single-children-3 menuparent"
                    >
                      <a href="/tos-ninos/" className="sf-depth-1 menuparent">
                        Tos en niños
                      </a>
                      <ul>
                        <li
                          id="menu-482-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-485-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-484-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                                className="sf-depth-3"
                              >
                                Tos con mocos
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-546-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Productos
                          </a>
                          <ul>
                            <li
                              id="menu-553-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Inistolin expectorante
                              </a>
                            </li>
                            <li
                              id="menu-554-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-537-1"
                          className="middle sf-item-3 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/remedios/" className="sf-depth-2">
                            Remedios para la tos en niños
                          </a>
                        </li>
                        <li
                          id="menu-487-1"
                          className="middle sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/causas/" className="sf-depth-2">
                            Causas
                          </a>
                        </li>
                        <li
                          id="menu-488-1"
                          className="middle sf-item-5 sf-depth-2 sf-total-children-6 sf-parent-children-0 sf-single-children-6 menuparent"
                        >
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="sf-depth-2 menuparent"
                          >
                            Desarrollo infantil
                          </a>
                          <ul>
                            <li
                              id="menu-501-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/7-anos/"
                                className="sf-depth-3"
                              >
                                7 años
                              </a>
                            </li>
                            <li
                              id="menu-498-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/8-anos/"
                                className="sf-depth-3"
                              >
                                8 años
                              </a>
                            </li>
                            <li
                              id="menu-497-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/9-anos/"
                                className="sf-depth-3"
                              >
                                9 años
                              </a>
                            </li>
                            <li
                              id="menu-495-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/10-anos/"
                                className="sf-depth-3"
                              >
                                10 años
                              </a>
                            </li>
                            <li
                              id="menu-493-1"
                              className="middle sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/11-anos/"
                                className="sf-depth-3"
                              >
                                11 años
                              </a>
                            </li>
                            <li
                              id="menu-490-1"
                              className="last sf-item-6 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/12-anos/"
                                className="sf-depth-3"
                              >
                                12 años
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-439-1"
                          className="last sf-item-6 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/dibujos-para-colorear/"
                            className="sf-depth-2"
                          >
                            Diviértete con Pandolín
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
          </div>
          <div id="main">
            <div id="content" className="column" role="main">
              <nav className="breadcrumb" role="navigation">
                <h2 className="element-invisible">Se encuentra usted aquí</h2>
                <ol>
                  <li>
                    <a href="/">Inicio</a> |{" "}
                  </li>
                  <li>
                    <a href="/jarabe-iniston/">Productos</a> |{" "}
                  </li>
                  <li>
                    <a href="/jarabe-inistolin/pediatrico-expectorante/">
                      Niños
                    </a>{" "}
                    |{" "}
                  </li>
                  <li>Jarabe Inistolin pediátrico antitusivo</li>
                </ol>
              </nav>{" "}
              <a id="main-content" />
              <div
                id="block-iniston-product-iniston-product-list"
                className="block block-iniston-product first odd"
              >
                <div id="iniston-product-list">
                  <span className="title">
                    Encuentra el Iniston correcto para ti
                  </span>
                  <p className="desc">
                    Iniston cuenta con una amplia gama de jarabes para la tos,
                    cada uno de ellos diseñado para actuar en función del tipo
                    de tos y de quien la padece.
                  </p>
                  <div className="products-wrapper">
                    <div className="adult">
                      <span className="section-title">Para adultos</span>
                      <div className="product-cough first">
                        <div className="cough bg-red">
                          <span>Tos con mocos</span>
                        </div>
                        <div className="product-wrapper">
                          <div className="congestion">
                            <span>Con congestión nasal</span>
                          </div>
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/#ficha"
                            className="product t-9 t-8 t-11 n-9"
                            title="Jarabe Iniston expectorante y descongestivo"
                          >
                            <img
                              src="/sites/default/files/styles/product_teaser/public/product/jarabe-iniston-expectorante-y-descongestivo-teaser_0.png?itok=vY2PWPDt"
                              width="107"
                              height="213"
                              alt="Jarabe Iniston expectorante y descongestivo"
                            />
                          </a>{" "}
                        </div>
                        <div className="product-wrapper">
                          <div className="congestion">
                            <span>Sin congestión nasal</span>
                          </div>
                          <a
                            href="/jarabe-iniston/expectorante/#ficha"
                            className="product t-9 t-8 t-12 n-20"
                            title="Jarabe Iniston expectorante"
                          >
                            <img
                              src="/sites/default/files/styles/product_teaser/public/product/iniston-expectorante-teaser_0.png?itok=HpfXr-01"
                              width="107"
                              height="213"
                              alt="Jarabe Iniston expectorante"
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <div className="product-cough last">
                        <div className="cough bg-yellow">
                          <span>Exceso de mucosidad</span>
                        </div>
                        <div className="product-wrapper">
                          <div className="congestion">
                            <span>Exceso de mucosidad</span>
                          </div>
                          <a
                            href="/jarabe-iniston/mucolitico/#ficha"
                            className="product t-9 t-8 t-12 n-23"
                            title="Jarabe Iniston mucolítico"
                          >
                            <img
                              src="/sites/default/files/styles/product_teaser/public/product/iniston-mucolitico-teaser_0.png?itok=I2gsl3VF"
                              width="107"
                              height="213"
                              alt="Jarabe Iniston mucolítico"
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <div className="product-cough middle">
                        <div className="cough bg-blue">
                          <span>Tos seca</span>
                        </div>
                        <div className="product-wrapper">
                          <div className="congestion">
                            <span>Con congestión nasal</span>
                          </div>
                          <a
                            href="/jarabe-iniston/antitusivo-descongestivo/#ficha"
                            className="product t-9 t-7 t-11 n-21"
                            title="Jarabe Iniston antitusivo y descongestivo"
                          >
                            <img
                              src="/sites/default/files/styles/product_teaser/public/product/iniston-antitusivo-descongestivo-teaser_0.png?itok=rHVbQywc"
                              width="107"
                              height="213"
                              alt="Jarabe Iniston antitusivo y descongestivo"
                            />
                          </a>{" "}
                        </div>
                        <div className="product-wrapper">
                          <div className="congestion">
                            <span>Sin congestión nasal</span>
                          </div>
                          <a
                            href="/jarabe-iniston/antitusivo/#ficha"
                            className="product t-9 t-7 t-12 n-22"
                            title="Jarabe Iniston antitusivo"
                          >
                            <img
                              src="/sites/default/files/styles/product_teaser/public/product/iniston-antitusivo-teaser_0.png?itok=AGHV5s4-"
                              width="107"
                              height="213"
                              alt="Jarabe Iniston antitusivo"
                            />
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="children">
                      <span className="section-title">Para niños</span>
                      <div className="product-cough first">
                        <div className="cough bg-red">
                          <span>Tos con mocos</span>
                        </div>
                        <div className="product-wrapper">
                          <div className="congestion">
                            <span>Con congestión nasal</span>
                          </div>
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/#ficha"
                            className="product t-10 t-8 t-11 n-24"
                            title="Jarana Instolin pedriátrico expectorante"
                          >
                            <img
                              src="/sites/default/files/styles/product_teaser/public/product/inistolin-pediatrico-expectorante-teaser_0_0.png?itok=oN3nrP6f"
                              width="107"
                              height="213"
                              alt="Jarana Instolin pedriátrico expectorante"
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <div className="product-cough last">
                        <div className="cough bg-blue">
                          <span>Tos seca</span>
                        </div>
                        <div className="product-wrapper">
                          <div className="congestion">
                            <span>Con congestión nasal</span>
                          </div>
                          <a
                            href="/jarabe-inistolin/pediatrico-antitusivo/#ficha"
                            className="product t-10 t-7 t-11 n-25 product-page"
                            title="Jarabe Inistolin pediátrico antitusivo"
                          >
                            <img
                              src="/sites/default/files/styles/product_teaser/public/product/inistolin-pediatrico-antitusivo-teaser_0_0.png?itok=OubwNSo8"
                              width="107"
                              height="213"
                              alt="Jarabe Inistolin pediátrico antitusivo"
                            />
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <article
                className="node-25 node node-product view-mode-full target-10 clearfix"
                itemScope
                itemType="http://schema.org/Product"
              >
                <a name="ficha" />
                <div className="product-wrapper">
                  <div className="field field-name-field-product-pre-title field-type-text field-label-hidden">
                    <div className="field-items">
                      <div className="field-item even">
                        PARA NIÑOS CON TOS SECA Y CONGESTIÓN NASAL
                      </div>
                    </div>
                  </div>{" "}
                  <h1 itemProp="name" className="product-name">
                    Jarabe Inistolin pediátrico antitusivo
                  </h1>
                  <div className="field field-name-field-product-img field-type-image field-label-hidden">
                    <div className="field-items">
                      <div className="field-item even">
                        <img
                          itemProp="image"
                          src="/sites/default/files/product/inistolin-pediatrico-antitusivo.png"
                          width="373"
                          height="587"
                          alt="Jarabe Inistolin pediátrico antitusivo"
                          title="Jarabe Inistolin pediátrico antitusivo"
                        />
                      </div>
                    </div>
                  </div>
                  <div itemProp="description">
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div className="field-item even">
                          <h2>INDICACIÓN</h2>
                          <p>
                            El jarabe infantil Inistolín Pediátrico Antitusivo
                            proporciona un alivo eficaz frente a la tos
                            improductiva (tos irritativa o nerviosa) y a la
                            congestión nasal asociadas a resfriado común y
                            gripe.
                          </p>
                          <h2>PRINCIPIOS ACTIVOS</h2>
                          <p>
                            Inistolin Pediátrico Antitusivo &nbsp;contiene 2
                            principios activos:
                          </p>
                          <ul className="list-check">
                            <li>
                              <p>
                                Dextrometorfano, Actúa a nivel central
                                produciendo la depresión del centro medular de
                                la tos al disminuir la producción de
                                taquicininas, los principales neurotransmisores
                                de las fibras C, que constituyen dicho centro de
                                control. Se absorbe rápidamente en el tracto
                                gastrointestinal y los efectos comienzan a los
                                15-30 minutos, prolongándose durante 6 horas.
                              </p>
                            </li>
                            <li>
                              <p>
                                Pseudoefedrina, Debido a su mecanismo de acción,
                                produce constricción de los vasos sanguíneos de
                                la mucosa nasal aliviando la congestión, y
                                además es de rápida absorción. Tras la
                                administración oral, la descongestión nasal se
                                produce a los 15-30 minutos aproximadamente y
                                permanece durante 3-4 horas.
                              </p>
                            </li>
                          </ul>
                          <h2>MODO DE EMPLEO</h2>
                          <p>
                            Niños de entre 7 y 12 años, 5 ml cada 8 horas (3
                            veces al día).
                          </p>
                          <p>
                            Debe consultar a su médico si empeora o si no mejora
                            después de 5 días.
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="product-footer-wrapper">
                  <div className="product-footer">
                    <div className="field field-name-field-product-patient-leaflet field-type-file field-label-hidden">
                      <div className="field-items">
                        <div className="field-item even">
                          <span className="file">
                            <a
                              href="/sites/default/files/product/prospecto-inistolin-antitusivo.pdf"
                              type="application/pdf; length=224841"
                              target="_blank"
                              title="prospecto-inistolin-antitusivo.pdf"
                            >
                              Descargar prospecto
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      itemProp="offers"
                      itemScope
                      itemType="http://schema.org/Offer"
                      className="product-price"
                    >
                      <meta itemProp="priceCurrency" content="EUR" />
                      <meta itemProp="priceValidUntil" content="2016-09-21" />
                      <span className="btn btn-blue show-price">
                        Ver precio
                      </span>
                      <span itemProp="price" className="price">
                        6,72€
                      </span>
                    </div>
                    <div className="service-links">
                      <ul className="links">
                        <li className="service-links-facebook first">
                          <a
                            href="http://www.facebook.com/sharer.php?u=https%3A//www.inistonparalatos.es/jarabe-inistolin/pediatrico-antitusivo&t=Jarabe%20Inistolin%20pedi%C3%A1trico%20antitusivo"
                            title="Compartir en Facebook"
                            className="service-links-facebook"
                            rel="nofollow"
                            target="_blank"
                          >
                            Facebook
                          </a>
                        </li>
                        <li className="service-links-twitter">
                          <a
                            href="http://twitter.com/share?url=https%3A//www.inistonparalatos.es/jarabe-inistolin/pediatrico-antitusivo&text=Jarabe%20Inistolin%20pedi%C3%A1trico%20antitusivo"
                            title="Compartir en Twitter"
                            className="service-links-twitter"
                            rel="nofollow"
                            target="_blank"
                          >
                            Twitter
                          </a>
                        </li>
                        <li className="service-links-google-plus last">
                          <a
                            href="https://plus.google.com/share?url=https%3A//www.inistonparalatos.es/jarabe-inistolin/pediatrico-antitusivo"
                            title="Compartir en Google+"
                            className="service-links-google-plus"
                            rel="nofollow"
                            target="_blank"
                          >
                            Google+
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="field field-name-field-highlight-ref field-type-entityreference field-label-hidden">
                  <div className="field-items">
                    <div className="field-item even">
                      <article className="node-88 node node-highlight node-teaser clearfix">
                        <header>
                          <h2 className="node__title node-title">
                            <a href="/jarabe-iniston/antitusivo-descongestivo/">
                              Alivia la tos seca con congestión nasal
                            </a>
                          </h2>
                        </header>
                        <div className="node-content-wrapper">
                          <div className="node-content">
                            <div className="group-highlight-left field-group-div">
                              <div className="field field-name-field-highlight-img field-type-image field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even">
                                    <img
                                      src="/sites/default/files/styles/highlight/public/highlight/alivia-tos-seca-congestion-nasal.png?itok=JrcpzPZv"
                                      width="302"
                                      height="157"
                                      alt="Alivia la tos seca con congestión nasal"
                                      title="Alivia la tos seca con congestión nasal"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="group-highlight-right field-group-div">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even">
                                    <p>
                                      Cuando tienes tos seca y además congestión
                                      nasal, su doble acción actúa aliviándolos
                                      en procesos catarrales.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="field field-name-field-highlight-link field-type-link-field field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even">
                                    <a
                                      href="/jarabe-iniston/antitusivo-descongestivo/"
                                      className="btn btn-blue"
                                    >
                                      Ver contenido
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul className="links inline">
                              <li className="node-readmore first last">
                                <a
                                  href="/destacado/alivia-la-tos-seca-con-congestion-nasal/"
                                  rel="tag"
                                  title="Alivia la tos seca con congestión nasal"
                                >
                                  Leer más
                                  <span className="element-invisible">
                                    {" "}
                                    sobre Alivia la tos seca con congestión
                                    nasal
                                  </span>
                                </a>
                              </li>
                            </ul>{" "}
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="field-item odd">
                      <article className="node-81 node node-highlight node-teaser clearfix">
                        <header>
                          <h2 className="node__title node-title">
                            <a href="/tos-ninos/tipos-de-tos-ninos/irritativa/">
                              Alivia la tos seca en los peques
                            </a>
                          </h2>
                        </header>
                        <div className="node-content-wrapper">
                          <div className="node-content">
                            <div className="group-highlight-left field-group-div">
                              <div className="field field-name-field-highlight-img field-type-image field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even">
                                    <img
                                      src="/sites/default/files/styles/highlight/public/highlight/alivia-tos-peques.jpg?itok=TuhYz2qX"
                                      width="302"
                                      height="157"
                                      alt
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="group-highlight-right field-group-div">
                              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even">
                                    <p>
                                      Conoce todo sobre la tos seca en niños y
                                      aprende cómo aliviarla con los jarabes de
                                      Inistolin para niños.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="field field-name-field-highlight-link field-type-link-field field-label-hidden">
                                <div className="field-items">
                                  <div className="field-item even">
                                    <a
                                      href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                                      className="btn btn-blue"
                                    >
                                      Ver contenido
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul className="links inline">
                              <li className="node-readmore first last">
                                <a
                                  href="/destacado/alivia-la-tos-seca-en-los-peques/"
                                  rel="tag"
                                  title="Alivia la tos seca en los peques"
                                >
                                  Leer más
                                  <span className="element-invisible">
                                    {" "}
                                    sobre Alivia la tos seca en los peques
                                  </span>
                                </a>
                              </li>
                            </ul>{" "}
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div id="navigation"></div>
          </div>
          <footer id="footer" className="region region-footer">
            <div
              id="block-menu-menu-menu-footer"
              className="block block-menu first odd"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-leaf first leaf">
                  <a href="/contacto/" className="menu__link">
                    Contacto
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-privacidad/" className="menu__link">
                    política de privacidad
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-cookies/" className="menu__link">
                    política de cookies
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/aviso-legal/" className="menu__link">
                    Aviso legal
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/acceso-profesionales/" className="menu__link">
                    Acceso profesionales
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/preguntas-frecuentes/" className="menu__link">
                    Faqs
                  </a>
                </li>
                <li className="menu__item is-leaf last leaf">
                  <a href="#" id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">
                    Configuración de cookies
                  </a>
                </li>
              </ul>
            </div>
            <div id="block-block-1" className="block block-block even">
              <p className="rtecenter">
                Publicada por Johnson &amp; Johnson S.A., único responsable de
                su contenido está dirigida a residentes en España.
                <br />
                ©&nbsp;Johnson &amp; Johnson S.A, 2015. Última actualización
                20/12/2014
              </p>
            </div>
            <div id="block-block-2" className="block block-block last odd">
              <p className="rtecenter">
                <img
                  alt
                  className="attr__format__media_original img__fid__100 img__view_mode__media_original media-image"
                  src="/sites/default/files/lea-las-instrucciones.png"
                  style={{
                    width: "180px"
                  }}
                />
                <img
                  alt
                  className="attr__format__media_original img__fid__101 img__view_mode__media_original media-image"
                  src="/sites/default/files/este-medicamento.png"
                  style={{
                    width: "193px"
                  }}
                />
                <img
                  alt
                  className="attr__format__media_original img__fid__102 img__view_mode__media_original media-image"
                  src="/sites/default/files/consulte-al-farmaceutico.png"
                  style={{
                    width: "180px"
                  }}
                />
              </p>
            </div>
          </footer>
        </div>
        <script src="/sites/default/files/js/js_pMpn-aGMsGKgMnAtmxLe-SZAZXUOnXxQqYAoiRN9DJI.js" />
        <script
          dangerouslySetInnerHTML={{
            __html: 'var eu_cookie_compliance_cookie_name = "";'
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction read_cookie(k,r){return(r=RegExp('(^|; )'+encodeURIComponent(k)+'=([^;]*)').exec(document.cookie))?r[2]:null;}\nvar cookie_agreed = read_cookie('cookie-agreed');\nif (cookie_agreed == 2) {\n/* <!--[CDATA[ */\nvar google_conversion_id = 1004307202;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]--> */\n}\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n  <div style="display:inline">\n    <img height="1" width="1" style="border-style:none" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1004307202/?value=0&amp;guid=ON&amp;script=0">\n  </div>\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
